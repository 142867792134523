import React, { useEffect, useState } from "react";
import {  Spinner, Modal, Button, Badge } from "react-bootstrap";
import { format, parseISO } from "date-fns";
import settings from "../config/Config";
import { IEmployeeHistory } from "../Types/PageTypes";

interface RmHistoryProps {
  show: boolean;
  employeeName: string;
  onClose: () => void;
}

const RmHistory: React.FC<RmHistoryProps> = ({
  show,
  employeeName,
  onClose,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [historyData, setHistoryData] = useState<IEmployeeHistory[] | null>(
    null
  );

  useEffect(() => {
    if (employeeName && show) {
      setLoading(true);
      const fetchHistory = async () => {
        try {
          const response = await fetch(
            `${settings.config.API_URL}EmployeeReportingMap/rm-history?employeeName=${employeeName}`
          );
          const data = await response.json();
          const processedData = data.map((record) => ({
            ...record,
            Status: record.EndDate ? "Previous" : "Current",
          }));
          setHistoryData(processedData);
        } catch (error) {
          console.error("Failed to fetch history data:", error);
        } finally {
          setLoading(false);
        }
      };
      fetchHistory();
    }
  }, [employeeName, show]);

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>RM History</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "200px" }}
          >
            <Spinner animation="border" variant="primary" />
          </div>
        ) : historyData && historyData.length > 0 ? (
          <div className="mx-3">
            {historyData.map((record, index) => (
              <div
                key={index}
                className="border p-3 mb-3 rounded position-relative"
                style={{
                  backgroundColor:
                    record.Status === "Current" ? "#D3F7BA" : "#E9BEBE",
                }}
              >
                <Badge 
                  bg={record.Status === "Current" ? "primary" : "danger"}
                  className="position-absolute"
                  style={{ 
                    top: "10px", 
                    right: "10px",
                    fontSize: "12px"
                  }}
                >
                  {record.Status}
                </Badge>
                <div className="d-flex flex-column">
                  <div className="mb-2">
                    <div
                      className="font-weight-bold"
                      style={{ fontSize: "16px" }}
                    >
                      {record.UserName}
                    </div>
                    <div className="text-muted" style={{ fontSize: "17px" }}>
                      <strong>{record.RMName}</strong>
                    </div>
                  </div>
                  <div>
                    <div className="text-muted">
                      Start Date:{" "}
                      {format(parseISO(record.StartDate), "yyyy-MM-dd")}
                    </div>
                    {record.Status === "Previous" && (
                      <div className="text-muted">
                        End Date:{" "}
                        {format(parseISO(record.EndDate), "yyyy-MM-dd")}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center">No history data available.</div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RmHistory;