import React, { useContext, useState, useEffect } from "react";
import { Container, Spinner, Table, Button } from "react-bootstrap";
import { SidebarContext } from "../contexts/SidebarContext";
import Search from "../components/Header/Search";
import settings from "../config/Config";
import { useUser } from "../contexts/UserContext";
import { format } from "date-fns";
import { PersonGear, PersonPlus } from "react-bootstrap-icons";
import Pagination from "../components/Header/Pagination";
import { toast, ToastContainer } from "react-toastify";
import RmHistory from "./RmHistory";
import UpdateReportingManager from "./UpdateReportingManager";
import AddUserModal from "./AddUser";

const RmMapping = () => {
  const { isOpen } = useContext(SidebarContext);
  const { user } = useUser();
  
  const [loading, setLoading] = useState(true);
  const [employeeData, setEmployeeData] = useState(null);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("All Statuses");
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [selectedEmployeeName, setSelectedEmployeeName] = useState("");
  const [selectedRM, setSelectedRM] = useState("");
  const [selectedEmployeeEmail, setSelectedEmployeeEmail] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  useEffect(() => {
    if (user) {
      const fetchData = async () => {
        const url = `${settings.config.API_URL}employee/employee-reportingmanagers?page=${currentPage}&limit=${recordsPerPage}&employeeName=${searchTerm}&RmName=${selectedStatus}`;
        const data = await fetch(url);
        const response = await data.json();
        setEmployeeData(response.data);
        setTotalPages(Math.ceil(response.total / recordsPerPage));
        setLoading(false);
      };
      fetchData();
    }
  }, [user, currentPage, recordsPerPage, searchTerm, selectedStatus]);

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);
  const handleSearch = (term) => {
    setSearchTerm(term);
    setCurrentPage(1);
  };
  const handleRefresh = () => {
    setSearchTerm("");
    setSelectedStatus(" ");
    setCurrentPage(1);
    setTotalPages(1);
  };

  const handleHistoryClick = (employeeName) => {
    setSelectedEmployeeName(employeeName);
    setShowHistoryModal(true);
  };

  const handleUpdateClick = (employeeName, employeeRM, employeeEmail) => {
    setSelectedEmployeeName(employeeName);
    setSelectedEmployeeEmail(employeeEmail);
    setSelectedRM(employeeRM);
    setShowUpdateModal(true);
  };

  const closeModals = () => {
    setShowHistoryModal(false);
    setShowUpdateModal(false);
    setShowAddUserModal(false);
    setSelectedEmployeeName("");
    setSelectedRM("");
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: "500px" }}>
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  return (
    <Container
      style={{
        marginLeft: isOpen ? "250px" : "60px",
        marginTop: "60px",
        padding: "20px",
        backgroundColor: "#F9FAFB",
        minHeight: "100vh",
        transition: "margin-left 0.3s",
        width: isOpen ? `calc(100% - 250px)` : `calc(100% - 60px)`,
        fontFamily: "Inter, sans-serif",
      }}
    >
      <div className="d-flex mb-3 align-items-center gap-2">
        <PersonGear style={{ fontSize: "25px" }} />
        <span className="font-semibold text-2xl">Employee Update</span>
      </div>
      <Container
        style={{
          border: "2px solid #dee2e6",
          borderRadius: "8px",
          padding: "20px",
          background: "#fff",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Container
          style={{
            borderRadius: "8px",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            padding: "0",
            background: "#fff",
            width: "100%",
          }}
        >
          <Container className="d-flex flex-nowrap p-3 gap-3 justify-content-left align-items-left">
            <Search
              onSearch={handleSearch}
              reset={false}
              resetSearchTrigger={false}
              placeholder="Search by Employee Name"
            />
            <Button
              className="bg-blue-500 text-white text-sm px-3 py-1 rounded d-flex align-items-center gap-2 ms-auto"
              style={{
                borderRadius: '12px',
                outline: 'none',
                border: 'none',
              }}
              onClick={() => setShowAddUserModal(true)}
            >
              <PersonPlus size={14} />
              Add User
            </Button>
          </Container>

          
            <Table className="min-w-full border border-gray-300 shadow-sm rounded-lg overflow-hidden">
              <thead className="bg-gray-100">
                <tr>
                  <th className="text-center py-3 border-b border-gray-300">ID</th>
                  <th className="text-center py-3 border-b border-gray-300">Employee Name</th>
                  <th className="text-center py-3 border-b border-gray-300">Reporting Manager</th>
                  <th className="text-center py-3 border-b border-gray-300">Start Date</th>
                  <th className="text-center py-3 border-b border-gray-300">Actions</th>
                </tr>
              </thead>
              <tbody>
                {employeeData && employeeData.length > 0 ? (
                  employeeData.map((employee) => (
                    <tr key={employee.EmployeeID} className="hover:bg-gray-100">
                      <td className="py-3 text-center border-b border-gray-300">
                        {employee.EmployeeID}
                      </td>
                      <td className="py-3 text-center border-b border-gray-300">
                        {employee.EmployeeName}
                      </td>
                      <td className="py-3 text-center border-b border-gray-300">
                        {employee.RMName}
                      </td>
                      <td className="py-3 text-center border-b border-gray-300">
                        {format(new Date(employee.StartDate), "yyyy-MM-dd")}
                      </td>
                      <td className="py-3 text-center border-b border-gray-300">
                        <div className="flex justify-center gap-2">
                          <button
                            className="bg-blue-500 text-white text-sm px-3 py-1 rounded hover:bg-blue-600"
                            onClick={() => handleUpdateClick(
                              employee.EmployeeName,
                              employee.RMName,
                              employee.EmployeeEmail
                            )}
                          >
                            Update
                          </button>
                          <button
                            className="bg-secondary text-white text-sm px-3 py-1 rounded hover:bg-secondary-dark"
                            onClick={() => handleHistoryClick(employee.EmployeeName)}
                          >
                            History
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5} className="text-center py-4 text-gray-500 border-b border-gray-300">
                      No employees found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>

            {totalPages > 1 && (
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            )}
         

          {showHistoryModal && (
            <RmHistory
              show={showHistoryModal}
              employeeName={selectedEmployeeName}
              onClose={closeModals}
            />
          )}

          {showUpdateModal && (
            <UpdateReportingManager
              show={showUpdateModal}
              employeeName={selectedEmployeeName}
              onClose={closeModals}
              currentWfhLimit={5}
              onUpdate={handleRefresh}
              currentRM={selectedRM}
              IsActive={true}
              employeeEmail={selectedEmployeeEmail}
            />
          )}
          
          {showAddUserModal && (
            <AddUserModal
              show={showAddUserModal}
              onClose={closeModals}
              onUpdate={handleRefresh}
            />
          )}
          <ToastContainer />
        </Container>
      </Container>
    </Container>
  );
};

export default RmMapping;