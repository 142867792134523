import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Container, Modal, Spinner } from "react-bootstrap";
import { useUser } from "../contexts/UserContext";
import { SidebarContext } from "../contexts/SidebarContext";
import { Activity, CheckCircle, LogOut } from "react-feather";
import settings from "../config/Config";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  SidebarContextType,
  ApiResponse,
  ApiErrorResponse,
} from "../Types/Interface";
import {
  calculateWorkingDuration,
  fetchTodayRecord,
} from "../Helper/WorkDuration";
import { UserContextProps } from "../Types/PageTypes";
import Banner from "../components/Card/Banner";

const LogoutPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [actionLoading, setActionLoading] = useState<boolean>(false);
  const { isOpen } = useContext<SidebarContextType>(SidebarContext);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoggedOut, setIsLoggedOut] = useState<boolean>(false);
  const { user, logout: userLogout } = useUser() as UserContextProps;
  const [workingDuration, setWorkingDuration] = useState<string>("");
  const [loginTime, setLoginTime] = useState<Date | null>(null);
  const [logoutTime, setLogoutTime] = useState<Date | null>(null);
  const [logoutip, setLogoutIp] = useState<string | null>(null);
  const navigate = useNavigate();


  useEffect(() => {
    if (!user) {
      navigate("/");
    } else {
      loadTodayRecord();
    }
  }, [user, navigate]);

  useEffect(() => {
    if (loginTime) {
      const duration = calculateWorkingDuration(loginTime);
      setWorkingDuration(duration);
      const intervalId = setInterval(() => {
        setWorkingDuration(calculateWorkingDuration(loginTime));
      }, 60000);

      return () => clearInterval(intervalId);
    }
  }, [loginTime]);

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await fetch(settings.config.IP_API_URL);
        if (!response.ok) {
          throw new Error('Failed to fetch IP address');
        }
        const data = await response.json();
        setLogoutIp(data.ip);
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    };
  
    fetchIpAddress();
  }, []);

  const loadTodayRecord = async (): Promise<void> => {
    try {
      const result = await fetchTodayRecord(user?.email);

      if (result.error) {
        toast.error(result.error);
      } else {
        setLoginTime(result.loginTime);
        setLogoutTime(result.logoutTime);
        setIsLoggedOut(result.isLoggedOut);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async (): Promise<void> => {
    setShowModal(false);
    setActionLoading(true);
    const currentTime = new Date();

    try {
      const url = new URL("employee-work-tracker", settings.config.API_URL).toString();
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          employeeEmail: user?.email,
          logoutTime: currentTime.toISOString(),
          logoutIp:logoutip
        }),
      });

      if (response.ok) {
        const data = (await response.json()) as ApiResponse;
        toast.success("Checkout successful. Have a great day!");
        setIsLoggedOut(true);
        setLogoutTime(currentTime);
        if (typeof userLogout === "function") {
          userLogout();
        }
      } else {
        const errorData = (await response.json()) as ApiErrorResponse;
        toast.error(`Checkout failed: ${errorData.message}`);
      }
    } catch (error) {
      toast.error("An error occurred during checkout. Please try again.");
    } finally {
      setActionLoading(false);
    }
  };

  const handleLogoutClick = (): void => {
    setShowModal(true);
  };

  const handleCloseModal = (): void => {
    setShowModal(false);
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }
  return (
    <div
      style={{
        marginLeft: isOpen ? "250px" : "60px",
        marginTop: "60px",
        padding: "20px",
        backgroundColor: "#F9FAFB",
        minHeight: "100vh",
        transition: "margin-left 0.3s",
        fontFamily: "Inter, sans-serif",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          marginBottom: "20px",
          borderBottom: "1px solid #e0e0e0",
          paddingBottom: "10px",
        }}
      >
        <LogOut size={24} color="#3a86ff" />
        <h2
          style={{
            fontSize: "24px",
            fontWeight: 600,
            margin: 0,
            color: "#333",
          }}
        >
          Check Out
        </h2>
        
      </div>
      <div className="mt-3">
          {user?.record ? (
            <Banner
              variant="danger"
              message="You didn't checkout yesterday. Don’t miss out, complete your checkout now!"
              buttonLabel="Checkout Now"
              buttonVariant="danger"
              records={user.record}
            />
          ) : null}
        </div>
      <Container
        style={{
         
          borderRadius: "8px",
          padding: "0px",
          boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
        }}
      >
        <Card
          style={{
            padding: "20px",
            backgroundColor: "#fff",
            borderRadius: "8px",
            border: "2px solid #dee2e6",
          }}
        >
          <Card.Body className="text-center">
            <h4
              style={{
                fontWeight: 700,
                marginBottom: "20px",
                fontSize: "22px",
              }}
            >
              {isLoggedOut ? "Day Complete!" : "Time to wrap up your day?"}
            </h4>

            <div className="text-center mb-4">
              {isLoggedOut ? (
                <>
                  <div
                    style={{
                      fontSize: "18px",
                      color: "#28a745",
                      marginBottom: "10px",
                      fontWeight: 600,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CheckCircle size={24} style={{ marginRight: "10px" }} />
                    You've already completed your day!
                  </div>
                  <p
                    style={{
                      fontSize: "16px",
                      color: "#6c757d",
                      fontWeight: 400,
                    }}
                  >
                    CheckOut Time:{" "}
                    {logoutTime ? logoutTime.toLocaleTimeString() : "N/A"}
                  </p>
                  <p
                    style={{
                      fontSize: "18px",
                      color: "#495057",
                      marginTop: "20px",
                      fontWeight: 600,
                    }}
                  >
                    Thank you for your hard work today,{" "}
                    <strong>{user?.name}</strong>!
                  </p>
                  <p
                    style={{
                      fontSize: "16px",
                      color: "#6c757d",
                      fontWeight: 400,
                    }}
                  >
                    We look forward to seeing you again tomorrow.
                  </p>
                </>
              ) : (
                <>
                  <p
                    style={{
                      color: "#6c757d",
                      marginBottom: "20px",
                      fontSize: "16px",
                      fontWeight: 400,
                    }}
                  >
                    We hope you had a productive day! Ready to Check Out?
                  </p>

                  <div className="d-flex justify-content-center align-items-center mb-4">
                    <Activity
                      size={22}
                      color="#4a90e2"
                      style={{ marginRight: "10px" }}
                    />
                    <span
                      style={{
                        fontSize: "18px",
                        color: "#495057",
                        fontWeight: 600,
                      }}
                    >
                      Session duration: {workingDuration}
                    </span>
                  </div>

                  <p
                    style={{
                      fontSize: "18px",
                      color: "#495057",
                      fontWeight: 600,
                    }}
                  >
                    Thank you for your hard work today,{" "}
                    <strong>{user?.name}</strong>! Ready to sign off?
                  </p>
                  <p
                    style={{
                      fontSize: "16px",
                      color: "#6c757d",
                      fontWeight: 400,
                    }}
                  >
                    Don't forget to complete any pending tasks before Check Out.
                  </p>

                  {!actionLoading && !isLoggedOut && (
                    <Button
                      onClick={handleLogoutClick}
                      variant="primary"
                      size="lg"
                      className="mt-5"
                      style={{
                        backgroundColor: "#3a86ff",
                        border: "none",
                        width: "300px",
                        fontWeight: 600,
                        fontSize: "16px",
                      }}
                    >
                      CheckOut
                    </Button>
                  )}

                  {actionLoading && (
                    <Button
                      variant="primary"
                      size="lg"
                      className="mt-3"
                      style={{ width: "300px" }}
                      disabled
                    >
                      <Spinner animation="border" size="sm" /> Check Out...
                    </Button>
                  )}
                </>
              )}
            </div>
          </Card.Body>
        </Card>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />

        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title style={{ fontWeight: 600 }}>
              Confirm CheckOut
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ fontWeight: 400 }}>
            Are you sure you want to Checkout?
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={handleCloseModal}
              style={{ fontWeight: 600 }}
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={handleLogout}
              style={{ fontWeight: 600 }}
            >
              CheckOut
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
};
export default LogoutPage;