import React from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { MsalProvider } from "@azure/msal-react";
import LoginPage from "./pages/LoginPage/LoginPage.tsx";
import UserDetails from "./pages/Dashboard/UserDetails.tsx";
import { UserProvider } from "./contexts/UserContext.tsx";
import Sidebar from "./components/SideBar/Sidebar.tsx";
import { SidebarProvider } from "./contexts/SidebarContext.tsx";
import Navbar from "./components/NavBar/Navbar.tsx";
import Dashboard from "./pages/Dashboard/Dashboard.tsx";
import "bootstrap/dist/css/bootstrap.min.css";
import WorkFromHome from './pages/WorkFromHome.tsx'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import InSyncCheckIn from "./Insync/Insync.tsx";
import LogoutPage from "./Insync/logout.tsx"
import AttendanceRouter from "./contexts/AttendanceRouter.tsx";
import MyRecord from './pages/MyRecord.tsx'
import ManageRecord from "./pages/ManageRecord.tsx";
import ProtectedRoute from "./utils/ProtectedRoute.tsx";
import RmMapping from "./pages/RmMapping.tsx";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AbsentEmployees from "./pages/Absentess.tsx";
const AuthenticatedContent = () => {
  return (
   
    <UserDetails>
      <UserProvider>
        <SidebarProvider>
          <Sidebar />
          <Navbar />
        
          <Routes>
            <Route path="/" element={<AttendanceRouter />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/work-from-home" element={<WorkFromHome />} />
            <Route path="/Attendance" element={<AttendanceRouter />}/>
            <Route path="/checkin" element={<InSyncCheckIn/>} />
            <Route path="/logout" element={<LogoutPage/>} />
            <Route path="/myrecords" element={<MyRecord/>} />
            <Route element={<ProtectedRoute requiresManager={true} />}>
            <Route path="/managerecords" element={<ManageRecord />} />
            <Route path="/rmmapping" element={<RmMapping/>} />
            <Route path="/compliancereport" element={<AbsentEmployees/>} />
          </Route>
          </Routes>
        </SidebarProvider>
      </UserProvider>
    </UserDetails>
    
  );
};

const WrappedView = () => {
  return (
    <div className="App">
      <AuthenticatedTemplate>
        <AuthenticatedContent />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoginPage />
      </UnauthenticatedTemplate>
    </div>
  );
};

const App = ({ instance }) => {
  return (
    <MsalProvider instance={instance}>
      <BrowserRouter>
      <WrappedView />
      </BrowserRouter>
      <ToastContainer/>
    </MsalProvider>
  );
};

export default App;
