import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import settings from "../config/Config";

const UpdateEmployeeDetails = ({
  show,
  employeeName,
  employeeEmail,
  currentRM,
  currentWfhLimit,
  IsActive,
  onClose,
  onUpdate,
}) => {
  const [managers, setManagers] = useState([]);
  const [selectedManager, setSelectedManager] = useState("");
  const [wfhLimit, setWfhLimit] = useState(currentWfhLimit || 5);
  const [wfhError, setWfhError] = useState("");
  const [employeeActive, setEmployeeActive] = useState(IsActive);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingManagers, setIsFetchingManagers] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isSelecting, setIsSelecting] = useState(false);

  const validateWfhLimit = (value) => {
    const numValue = Number(value);
    if (isNaN(numValue) || !Number.isInteger(numValue)) {
      setWfhError("WFH limit must be a whole number");
      return false;
    }
    if (numValue <= 0) {
      setWfhError("WFH limit must be greater than 0");
      return false;
    }
    if (numValue > 31) {
      setWfhError("WFH limit cannot exceed 31 days");
      return false;
    }
    setWfhError("");
    return true;
  };

  const handleWfhChange = (e) => {
    const value = e.target.value;
    setWfhLimit(value);
    validateWfhLimit(value);
  };

  const fetchManagers = async () => {
    if (isFetchingManagers || managers.length > 0) return;

    setIsFetchingManagers(true);
    try {
      const response = await fetch(
        `${settings.config.API_URL}EmployeeReportingMap/reporting-manager`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      const managerNames = data.map((item) => item.RMName);
      setManagers(managerNames);
    } catch (error) {
      console.error("Error fetching managers:", error);
      toast.error("Failed to fetch managers.");
      setManagers([]);
    } finally {
      setIsFetchingManagers(false);
    }
  };

  const searchNewRM = async (filter) => {
    if (!filter || isSelecting) {
      setSearchResults([]);
      return;
    }

    setIsSearching(true);
    try {
      const response = await fetch(
        `${settings.config.API_URL}EmployeeReportingMap/newrm?nameFilter=${filter}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setSearchResults(data);
    } catch (error) {
      console.error("Error searching RM:", error);
      toast.error("Failed to search for new RM.");
      setSearchResults([]);
    } finally {
      setIsSearching(false);
    }
  };

  useEffect(() => {
    if (show) {
      fetchManagers();
      validateWfhLimit(wfhLimit);
    }
  }, [show]);

  useEffect(() => {
    if (isSelecting) {
      setIsSelecting(false);
      return;
    }

    const debounceTimer = setTimeout(() => {
      if (searchTerm) {
        searchNewRM(searchTerm);
      } else {
        setSearchResults([]);
      }
    }, 300);

    return () => clearTimeout(debounceTimer);
  }, [searchTerm]);

  const handleSearchTermChange = (e) => {
    setIsSelecting(false);
    setSearchTerm(e.target.value);
  };

  const handleSelectSearchResult = (name) => {
    setIsSelecting(true);
    setSearchTerm(name);
    setSearchResults([]);
  };

  const handleUpdate = async () => {
    if (!validateWfhLimit(wfhLimit)) {
      toast.error("Please correct the WFH limit before updating");
      return;
    }

    const newRM = selectedManager || searchTerm;
    
    try {
      setIsLoading(true);
      
      const updatePayload = {
        email: employeeEmail,
        employeeName: employeeName,
        wfhDays: parseInt(wfhLimit),
        IsActive: employeeActive ? 1 : 0,
        newRMName: newRM !== currentRM ? newRM : undefined
      };

      const response = await fetch(
        `${settings.config.API_URL}employee/update-employee`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(updatePayload)
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      toast.success("Employee details updated successfully");
      onUpdate();
      onClose();
    } catch (error) {
      console.error("Error updating employee details:", error);
      toast.error("Failed to update employee details");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="text-xl font-bold">
          Update Employee Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Form>
          <Form.Group controlId="employeeName" className="mb-4">
            <Form.Label className="font-semibold">Employee Name</Form.Label>
            <Form.Control type="text" value={employeeName} readOnly />
          </Form.Group>

          <Form.Group
            controlId="searchManager"
            className="mb-4 position-relative"
          >
            <Form.Label className="font-semibold">
              Search New Manager
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Type to search..."
              value={searchTerm}
              onChange={handleSearchTermChange}
              autoComplete="off"
              className="mb-1"
            />
            {isSearching && (
              <div className="mt-1 ps-2">
                <Spinner size="sm" /> Searching...
              </div>
            )}
            {searchResults &&
              searchResults.length > 0 &&
              searchTerm &&
              !isSelecting && (
                <div
                  className="position-absolute start-0 end-0 mt-1 border rounded bg-white shadow-sm z-1000"
                  style={{ maxHeight: "200px", overflowY: "auto" }}
                >
                  {searchResults.map((result, index) => (
                    <div
                      key={index}
                      className="px-3 py-2 cursor-pointer hover:bg-light"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSelectSearchResult(result.Name)}
                    >
                      {result.Name}
                    </div>
                  ))}
                </div>
              )}
          </Form.Group>

          <Form.Group controlId="selectManager" className="mb-4">
            <Form.Label className="font-semibold">
              Or Select from Existing Managers
            </Form.Label>
            <Form.Control
              as="select"
              value={selectedManager}
              onChange={(e) => {
                setSelectedManager(e.target.value);
                setSearchTerm("");
                setSearchResults([]);
              }}
            >
              <option value="">{currentRM}</option>
              {managers?.length > 0 ? (
                managers.map((manager, index) => (
                  <option key={index} value={manager}>
                    {manager}
                  </option>
                ))
              ) : (
                <option disabled>No managers available</option>
              )}
            </Form.Control>
          </Form.Group>

          <div className="row align-items-center">
            <div className="col-6">
              <Form.Group controlId="employeeStatus" className="mb-0">
                <Form.Label className="font-semibold mb-0">
                  Employee Status
                </Form.Label>
                <div
                  className="d-flex align-items-center"
                  style={{ height: "38px" }}
                >
                  <Form.Check
                    type="switch"
                    id="employee-status-switch"
                    checked={employeeActive}
                    onChange={(e) => setEmployeeActive(e.target.checked)}
                    label={employeeActive ? "Active" : "Inactive"}
                    className="mt-0"
                  />
                </div>
              </Form.Group>
            </div>
            <div className="col-6">
              <Form.Group controlId="wfhLimit" className="mb-0">
                <Form.Label className="font-semibold">
                  WFH Days Limit
                </Form.Label>
                <Form.Control
                  type="number"
                  min="1"
                  max="31"
                  value={wfhLimit}
                  onChange={handleWfhChange}
                  isInvalid={!!wfhError}
                />
                <Form.Control.Feedback type="invalid">
                  {wfhError}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose} disabled={isLoading}>
          Cancel
        </Button>
        <Button 
          variant="primary" 
          onClick={handleUpdate} 
          disabled={isLoading || !!wfhError}
        >
          {isLoading ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />{" "}
              Updating...
            </>
          ) : (
            "Update"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateEmployeeDetails;