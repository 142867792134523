import React, { useContext, useState } from "react";
import { SidebarContext } from "../contexts/SidebarContext";
import {
  Col,
  Row,
  InputGroup,
  Form,
  Container,
  Button,
  Spinner,
} from "react-bootstrap";
import { useUser } from "../contexts/UserContext";
import axios from "axios";
import settings from "../config/Config";
import { useFetchBalance } from "../Hooks/UseFetchBalance";
import reset from "../assets/reset.png";
import { useMediaQuery } from "react-responsive";
import {
  validateWfhLimits,
} from '../Helper/validateDates';
import calendar from "../assets/calendar.png";
import textuser from "../assets/text.png";
import userIcon from "../assets/managerIcon.png";
import Note from '../components/Note/index';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IUser, IErrors } from "../Types/PageTypes";
import { handleStartDateChange, handleEndDateChange } from "../Helper/validateDates";
import SvgIcon from "../components/SVG/SvgIcons";

const WorkFromHome: React.FC = () => {
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [reason, setReason] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [errors, setErrors] = useState<IErrors>({});
  const { isOpen } = useContext(SidebarContext);
  const { user } = useUser() as { user: IUser };
  const { balance } = useFetchBalance(user);
  const [loading, setLoading] = useState<boolean>(false);

  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 992px)" });

  if (!user) {
    return <div>Loading...</div>;
  }

  const validateForm = async (): Promise<boolean> => {
    const newErrors: IErrors = {};


    if (!startDate.trim()) {
      newErrors.startDate = "Start date is required";
    }
    if (!endDate.trim()) {
      newErrors.endDate = "End date is required";
    }

    if (!reason.trim()) {
      newErrors.reason = "Reason is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const isValid = await validateForm();
    if (!isValid) {
      toast.error("Validation errors.");
      setLoading(false);
      return;
    }

    const wfhLimitsValid = await validateWfhLimits(
      startDate,
      endDate,
      user,
      setErrors
    );
    if (!wfhLimitsValid) {
      toast.error("Please check your input.");
      setLoading(false);
      return;
    }

    const nameParts = user.name ? user.name.split(" ") : [];
    const first_name = nameParts[0] || "";
    const last_name = nameParts[1] || "";
    const start_date = new Date(startDate).toISOString();
    const end_date = new Date(endDate).toISOString();
    const created_at = new Date().toISOString();
    const updated_at = created_at;

    const sendingData = {
      first_name,
      last_name,
      email: user.email,
      manager_email: user.managerEmail,
      start_date,
      end_date,
      reason,
      status: settings.constants.status[3],
      created_at,
      updated_at,
      department: user.department || null,
      position: user.position || null,
      uuid: Math.random().toString(36).substring(2),
      manager_name: user.managerName,
    };

    try {
      const response = await axios.post(
        `${settings.config.API_URL}wfh-activity-log`,
        sendingData
      );
      setStartDate("");
      setEndDate("");
      setReason("");
      toast.success("Form submitted successfully!");
    } catch (error) {
      toast.error("Failed to submit the form.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container
      className="min-h-screen"
      style={{
        marginLeft: isOpen ? "250px" : "60px",
        marginTop: "60px",
        padding: "20px",
        backgroundColor: "#F9FAFB",
        transition: "margin-left 0.3s",
        width: isOpen ? `calc(100% - 250px)` : `calc(100% - 60px)`,
      }}
    >
      <div className="d-flex mb-3 align-items-center gap-2">
        <SvgIcon iconType="remoteWork" width={20} height={20} />
        <span className="font-semibold text-2xl">Remote Work</span>
      </div>

      <Container
        style={{
          border: "2px solid #dee2e6",
          borderRadius: "8px",
          padding: "20px",
          backgroundColor: "#ffffff",
        }}
      >
        <Row style={{ fontSize: "20px", fontFamily: "Inter, sans-serif" }}>
          <Col md={6}>
            <div className="mb-2">
              <span className="font-semibold text-1xl">Registration</span>
            </div>
            
          </Col>
        </Row>

        <Row style={{ padding: "20px", fontFamily: "Inter, sans-serif" }}>
          <Col md={6}>
            <div style={{ display: "flex", gap: "5px" }}>
              <img
                src={calendar}
                alt=""
                style={{ width: "16.5px", height: "16.5px" }}
              />
              <p style={{ marginBottom: "10px", marginTop: "-5px", fontSize: 18, fontWeight: 400 }}>
                Start Date <span style={{ color: "red" }}>*</span>
              </p>
            </div>
            <InputGroup className="mb-3" style={{ maxWidth: "280px", marginRight: "10px" }}>
              <Form.Control
                type="date"
                value={startDate}
                onChange={(e) => handleStartDateChange(e, setStartDate, endDate, setErrors, user)}
                isInvalid={!!errors.startDate}
                style={{ fontFamily: "Inter, sans-serif", flexGrow: 1, borderRadius: "8px" }}
              />
              <Form.Control.Feedback type="invalid">
                {errors.startDate}
              </Form.Control.Feedback>
            </InputGroup>
          </Col>

          <Col md={6}>
            <div style={{ display: "flex", gap: "5px" }}>
              <img
                src={calendar}
                alt=""
                style={{ width: "16.5px", height: "16.5px" }}
              />
              <p style={{ marginBottom: "10px", marginTop: "-5px", fontSize: 18, fontWeight: 400 }}>
                End Date <span style={{ color: "red" }}>*</span>
              </p>
            </div>
            <InputGroup className="mb-3" style={{ maxWidth: "280px", marginRight: "10px" }}>
              <Form.Control
                type="date"
                value={endDate}
                onChange={(e) => handleEndDateChange(e, setEndDate, startDate, setErrors, user)}
                isInvalid={!!errors.endDate}
                style={{ fontFamily: "Inter, sans-serif", flexGrow: 1, borderRadius: "8px" }}
              />
              <Form.Control.Feedback type="invalid">
                {errors.endDate}
              </Form.Control.Feedback>
            </InputGroup>
          </Col>
        </Row>

        <Row style={{ padding: "20px", fontFamily: "Inter, sans-serif", marginTop: "-25px" }}>
          <Col md={6} style={{ marginTop: "-10px" }}>
            <div style={{ display: "flex", gap: "5px" }}>
              <img
                src={userIcon}
                alt=""
                style={{ width: "16.5px", height: "16.5px" }}
              />
              <p style={{ marginBottom: "10px", marginTop: "-5px", fontSize: 18, fontWeight: 400 }}>
                Reporting Manager
              </p>
            </div>
            <InputGroup className="mb-3" style={{ maxWidth: "280px", marginRight: "10px" }}>
              <Form.Control
                type="textarea"
                value={user.managerEmail || "NA"}
                readOnly
                style={{ fontFamily: "Inter, sans-serif", flexGrow: 1, borderRadius: "8px" }}
              />
            </InputGroup>
          </Col>
          <Col md={6}>
            <p style={{ marginBottom: "10px", marginTop: "-5px", fontSize: 18, fontWeight: 400 }}>
              Available Balance : {balance}
            </p>
          </Col>
        </Row>

        <Col style={{ padding: "20px", fontFamily: "Inter, sans-serif", marginTop: "-35px" }}>
          <div style={{ display: "flex", gap: "5px" }}>
            <img
              src={textuser}
              alt=""
              style={{ width: "15px", height: "15px" }}
            />
            <p style={{ marginBottom: "10px", marginTop: "-6px", fontSize: 18, fontWeight: 400 }}>
              Reason <span style={{ color: "red" }}>*</span>
            </p>
          </div>
          <InputGroup style={{ height: "120px" }}>
            <Form.Control
              as="textarea"
              rows={4}
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              isInvalid={!!errors.reason}
              placeholder="Enter your reason..... "
              style={{ resize: "none", borderRadius: "8px" }}
            />
            <Form.Control.Feedback type="invalid">
              {errors.reason}
            </Form.Control.Feedback>
          </InputGroup>
        </Col>

        <Row>
          <Col>
            <div style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: isDesktopOrLaptop ? "20px" : "5px",
              padding: "20px",
              marginTop: "-15px"
            }}>
              <Button
                style={{
                  backgroundColor: "#ebf0f7",
                  color: "black",
                  border: "none",
                  fontSize: isDesktopOrLaptop ? "15px" : "13px",
                  width: isDesktopOrLaptop ? "100px" : "80px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  gap: "5px",
                }}
                onClick={() => {
                  setStartDate("");
                  setEndDate("");
                  setReason("");
                  setMessage("");
                }}
              >
                <img
                  src={reset}
                  alt=""
                  style={{
                    width: isDesktopOrLaptop ? "18px" : "13px",
                    height: isDesktopOrLaptop ? "18px" : "13px",
                    marginBottom: isDesktopOrLaptop ? "-1.5px" : "",
                  }}
                />
                <span>Reset</span>
              </Button>
              <Button
                style={{
                  color: "white",
                  fontSize: isDesktopOrLaptop ? "15px" : "13px",
                  width: "110px",
                  height: isDesktopOrLaptop ? "" : "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
                onClick={handleSubmit}
                variant="primary"
                disabled={loading}
              >
                {loading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="visually-hidden">Loading...</span>
                  </>
                ) : (
                  "Submit"
                )}
              </Button>
            </div>
          </Col>
        </Row>
        <Note />
      </Container>
      <ToastContainer />
    </Container>
  );
};

export default WorkFromHome;