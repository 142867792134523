import React, { useContext, useState, useEffect } from "react";
import { Container, Table } from "react-bootstrap";
import { SidebarContext } from "../contexts/SidebarContext";
import Search from "../components/Header/Search";
import Filter, { FilterProps } from "../components/Header/Filter";
import { FilterOption } from '../components/Header/Filter';
import Refresh from "../components/Header/Refresh";
import Pagination from "../components/Header/Pagination";
import SvgIcon from "../components/SVG/SvgIcons";
import { PersonSlash } from "react-bootstrap-icons";
import settings from "../config/Config";
interface Employee {
  id: string;
  employeeId: string;
  email: string;
  name: string;
  location: string;
}

const AbsentEmployees = () => {
  const { isOpen } = useContext(SidebarContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [sortByName, setSortByName] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [recordsPerPage] = useState<number>(10);
  const [resetTrigger, setResetTrigger] = useState<boolean>(false);

  const [locationFilter, setLocationFilter] = useState<FilterOption>("All Locations");

  const locations: FilterOption[] = [
    'All Locations',
    'Coimbatore',
    'Chennai',
    'Bangalore',
  ];
  
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      fetchEmployees();
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [searchTerm]);

  useEffect(() => {
    fetchEmployees();
  }, [locationFilter, sortByName, currentPage]);

  const fetchEmployees = async () => {
    setLoading(true);
    try {
      const location =
        locationFilter === "All Locations" ? "null" : locationFilter;
      const encodedSearch = encodeURIComponent(searchTerm.trim());
      const encodedLocation = encodeURIComponent(location);

      const baseUrl = settings.config.API_URL.replace(/\/+$/, "");
      const endpoint = "/EmployeeReportingMap/absent";
      const url = `${baseUrl}${endpoint}?locationFilter=${encodedLocation}&nameFilter=${encodedSearch}&sortByName=${sortByName}&page=${currentPage}&limit=${recordsPerPage}`;

      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (!data || !data.employees) {
        console.error("Invalid response format:", data);
        setEmployees([]);
        setTotalPages(1);
      } else {
        const transformedData = data.employees.map((emp) => ({
          id: emp.id,
          employeeId: emp.id,
          email: emp.email,
          name: emp.name,
          location: emp.location,
        }));
        setEmployees(transformedData);
        setTotalPages(data.totalPages);
      }
    } catch (error) {
      console.error("Error fetching employees:", error);
      setEmployees([]);
      setTotalPages(1);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (term: string) => {
    setSearchTerm(term);
    setCurrentPage(1);
  };

  const handleLocationSelect = (location: FilterOption) => {
    setLocationFilter(location);
    setCurrentPage(1);
  };

  const handleRefresh = () => {
    setSearchTerm("");
    setLocationFilter("All Locations");
    setSortByName(1);
    setCurrentPage(1);
    setResetTrigger((prev) => !prev);
    fetchEmployees();
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };
  const filterProps: FilterProps = {
    options: locations,
    onSelectStatus: handleLocationSelect,
    radiobutton: false,
    reset: resetTrigger,
    defaultValue: "All Locations"
  };
  return (
    <Container
      style={{
        marginLeft: isOpen ? "250px" : "60px",
        marginTop: "60px",
        padding: "20px",
        backgroundColor: "#F9FAFB",
        minHeight: "100vh",
        transition: "margin-left 0.3s",
        width: isOpen ? `calc(100% - 250px)` : `calc(100% - 60px)`,
      }}
    >
      <div className="d-flex mb-3 align-items-center gap-2">
        <PersonSlash width={25} height={25} />
        <span className="font-semibold text-2xl">Compliance Report</span>
      </div>
      <Container
        style={{
          border: "2px solid #dee2e6",
          borderRadius: "8px",
          padding: "20px",
          background: "#fff",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Container
          style={{
            borderRadius: "8px",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            padding: "0",
            background: "#fff",
            width: "100%",
          }}
        >
          {/* <Container className="bg-white rounded-lg p-4 border"> */}
          <Container className="d-flex flex-nowrap p-1 gap-3 justify-content-left align-items-left mb-2">
              <Search
                onSearch={handleSearch}
                reset={resetTrigger}
                resetSearchTrigger={resetTrigger} 
                placeholder="Search by Employee Name"
              />
              <Filter {...filterProps} />
              <Refresh onRefresh={handleRefresh} />
            </Container>
            
            <Table className="min-w-full border border-gray-300 shadow-sm rounded-lg overflow-hidden">
              <thead className="bg-gray-100">
                <tr>
                  <th className="text-center py-3 border-b border-gray-300">
                    Employee ID
                  </th>
                  <th className="text-center py-3 border-b border-gray-300">
                    Email
                  </th>
                  <th className="text-center py-3 border-b border-gray-300">
                    Name
                  </th>
                  <th className="text-center py-3 border-b border-gray-300">
                    Location
                  </th>
                </tr>
              </thead>
              <tbody>
                {employees.length > 0 ? (
                  employees.map((employee: Employee) => (
                    <tr key={employee.id} className="hover:bg-gray-100 ">
                      <td className="py-3 text-center border-b border-gray-300">
                        {employee.employeeId}
                      </td>
                      <td className="py-3 text-center border-b border-gray-300">
                        {employee.email}
                      </td>
                      <td className="py-3 text-center border-b border-gray-300">
                        {employee.name}
                      </td>
                      <td className="py-3 text-center border-b border-gray-300">
                        {employee.location}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4} className="text-center">
                      No employees found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>

            {totalPages > 0 && (
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            )}
          </Container>
        </Container>
      </Container>
    // </Container>
  );
};

export default AbsentEmployees;
