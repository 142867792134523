import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { PersonPlus } from "react-bootstrap-icons";
import settings from "../config/Config";

interface AddUserModalProps {
  show: boolean;
  onClose: () => void;
  onUpdate: () => void;
}

interface RoleMapping {
  [key: string]: number;
  admin: number;
  superadmin: number;
  user: number;
}

interface SearchResult {
  Name: string;
}

interface ValidationErrors {
  ID: string;
  email: string;
  Contact: string;
}

const AddUserModal: React.FC<AddUserModalProps> = ({ show, onClose, onUpdate }) => {
  const roleMapping: RoleMapping = {
    admin: 2,
    superadmin: 3,
    user: 1
  };

  const locationOptions = ["Select Location", "Coimbatore", "Chennai", "Bangalore"];

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    ID: '',
    Name: '',
    email: '',
    RoleId: 'user',
    Location: '',
    Contact: '',
    RMName: ''
  });

  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({
    ID: '',
    email: '',
    Contact: ''
  });

  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isSelecting, setIsSelecting] = useState(false);

  const validateID = (id: string): string => {
    if (!id) return 'ID is required';
    if (!/^\d+$/.test(id)) {
      return 'ID must contain only numbers';
    }
    return '';
  };

  const validateEmail = (email: string): string => {
    if (!email) return 'Email is required';
    if (!email.endsWith('@codincity.com')) {
      return 'Email must be a valid @codincity.com address';
    }
    return '';
  };

  const validatePhone = (phone: string): string => {
    if (!phone) return 'Contact number is required';
    if (!/^\d{10}$/.test(phone)) {
      return 'Contact must be exactly 10 digits';
    }
    return '';
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLElement>) => {
    const target = e.target as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
    const { name, value } = target;
   
    if (name === 'ID' && value !== '') {
      const numbersOnly = value.replace(/[^0-9]/g, '');
      setFormData(prev => ({
        ...prev,
        [name]: numbersOnly
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }

    
    if (name === 'ID' || name === 'email' || name === 'Contact') {
      setValidationErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  const validateField = (name: string, value: string) => {
    if (name === 'ID') {
      const error = validateID(value);
      setValidationErrors(prev => ({ ...prev, ID: error }));
      return !error;
    }
    if (name === 'email') {
      const error = validateEmail(value);
      setValidationErrors(prev => ({ ...prev, email: error }));
      return !error;
    }
    if (name === 'Contact') {
      const error = validatePhone(value);
      setValidationErrors(prev => ({ ...prev, Contact: error }));
      return !error;
    }
    return true;
  };

  const searchNewRM = async (filter: string) => {
    if (!filter || isSelecting) {
      setSearchResults([]);
      return;
    }

    setIsSearching(true);
    try {
      const response = await fetch(`${settings.config.API_URL}EmployeeReportingMap/newrm?nameFilter=${filter}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setSearchResults(data);
    } catch (error) {
      console.error('Error searching RM:', error);
      setSearchResults([]);
    } finally {
      setIsSearching(false);
    }
  };

  useEffect(() => {
    if (isSelecting) {
      setIsSelecting(false);
      return;
    }

    const debounceTimer = setTimeout(() => {
      if (searchTerm) {
        searchNewRM(searchTerm);
      } else {
        setSearchResults([]);
      }
    }, 300);

    return () => clearTimeout(debounceTimer);
  }, [searchTerm]);

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsSelecting(false);
    const value = e.target.value;
    setSearchTerm(value);
    setFormData(prev => ({
      ...prev,
      RMName: value
    }));
  };

  const handleSelectSearchResult = (name: string) => {
    setIsSelecting(true);
    setSearchTerm(name);
    setFormData(prev => ({
      ...prev,
      RMName: name
    }));
    setSearchResults([]);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const idError = validateField('ID', formData.ID);
    const emailError = validateField('email', formData.email);
    const phoneError = validateField('Contact', formData.Contact);

    if (!idError || !emailError || !phoneError) {
      toast.error('Please correct the validation errors');
      return;
    }

    setIsSubmitting(true);
    
    const apiPayload = {
      ...formData,
      RoleId: roleMapping[formData.RoleId as keyof RoleMapping]
    };

    try {
      const response = await fetch(`${settings.config.API_URL}employee/update-employee`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(apiPayload)
      });

      if (response.ok) {
        toast.success('User added successfully');
        onUpdate();
        onClose();
      } else {
        const errorData = await response.json();
        toast.error(errorData.message || 'Failed to add user');
      }
    } catch (error) {
      toast.error('Error adding user');
      console.error('Error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal show={show} onHide={onClose} size="lg">
      <Modal.Header closeButton className="border-0 pb-0">
        <Modal.Title className="text-xl font-bold d-flex align-items-center">
          <PersonPlus className="me-2" size={24} /> Add New User
        </Modal.Title>
      </Modal.Header>
      <hr className="mt-3 mb-4" />
      <Modal.Body className="pt-0">
        <Form onSubmit={handleSubmit}>
          <div className="grid grid-cols-2 gap-2">
            <Form.Group className="mb-4">
              <Form.Label className="font-bold text-base mb-2">
                ID <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="ID"
                value={formData.ID}
                onChange={handleInputChange}
                onBlur={(e) => validateField('ID', e.target.value)}
                required
                className={`h-10 text-base px-3 ${validationErrors.ID ? 'border-danger' : ''}`}
              />
              {validationErrors.ID && (
                <Form.Text className="text-danger">
                  {validationErrors.ID}
                </Form.Text>
              )}
            </Form.Group>
            
            <Form.Group className="mb-4">
              <Form.Label className="font-bold text-base mb-2">
                Name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="Name"
                value={formData.Name}
                onChange={handleInputChange}
                required
                className="h-10 text-base px-3"
              />
            </Form.Group>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <Form.Group className="mb-4">
              <Form.Label className="font-bold text-base mb-2">
                Email <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                onBlur={(e) => validateField('email', e.target.value)}
                required
                className={`h-10 text-base px-3 ${validationErrors.email ? 'border-danger' : ''}`}
              />
              {validationErrors.email && (
                <Form.Text className="text-danger">
                  {validationErrors.email}
                </Form.Text>
              )}
            </Form.Group>
            
            <Form.Group className="mb-4">
              <Form.Label className="font-bold text-base mb-2">
                Role <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                name="RoleId"
                value={formData.RoleId}
                onChange={handleInputChange}
                required
                className="h-10 text-base px-3"
              >
                <option value="user">User</option>
                <option value="admin">Admin</option>
                <option value="superadmin">Super Admin</option>
              </Form.Select>
            </Form.Group>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <Form.Group className="mb-4">
              <Form.Label className="font-bold text-base mb-2">
                Location <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                name="Location"
                value={formData.Location}
                onChange={handleInputChange}
                required
                className="h-10 text-base px-3"
              >
                {locationOptions.map((location, index) => (
                  <option key={index} value={location === "Select Location" ? "" : location} disabled={index === 0}>
                    {location}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            
            <Form.Group className="mb-4">
              <Form.Label className="font-bold text-base mb-2">
                Contact <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="tel"
                name="Contact"
                value={formData.Contact}
                onChange={handleInputChange}
                onBlur={(e) => validateField('Contact', e.target.value)}
                required
                className={`h-10 text-base px-3 ${validationErrors.Contact ? 'border-danger' : ''}`}
              />
              {validationErrors.Contact && (
                <Form.Text className="text-danger">
                  {validationErrors.Contact}
                </Form.Text>
              )}
            </Form.Group>
          </div>

          <Form.Group className="mb-4 position-relative">
            <Form.Label className="font-bold text-base mb-2">
              Reporting Manager Name <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Type to search..."
              value={searchTerm}
              onChange={handleSearchTermChange}
              autoComplete="off"
              required
              className="h-10 text-base px-3"
            />
            {isSearching && (
              <div className="mt-2 ps-2 text-sm text-gray-600">
                <Spinner size="sm" /> Searching...
              </div>
            )}
            {searchResults.length > 0 && searchTerm && !isSelecting && (
              <div 
                className="position-absolute start-0 end-0 mt-1 border rounded-md bg-white shadow-lg"
                style={{ 
                  maxHeight: '200px', 
                  overflowY: 'auto',
                  zIndex: 1000
                }}
              >
                {searchResults.map((result, index) => (
                  <div
                    key={index}
                    className="px-3 py-2 cursor-pointer hover:bg-gray-50 text-base"
                    onClick={() => handleSelectSearchResult(result.Name)}
                  >
                    {result.Name}
                  </div>
                ))}
              </div>
            )}
          </Form.Group>
          <hr className="my-4" />
          <div className="flex justify-end gap-3 mt-6">
            <Button 
              variant="secondary" 
              onClick={onClose}
              disabled={isSubmitting}
              className="px-4 py-2 text-sm font-medium"
            >
              Cancel
            </Button>
            <Button 
              variant="primary" 
              type="submit"
              disabled={isSubmitting}
              className="px-4 py-2 text-sm font-medium d-flex align-items-center"
            >
              {isSubmitting ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-2"
                  />
                  Adding User...
                </>
              ) : (
                <>
                  <PersonPlus className="me-2" /> Add User
                </>
              )}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddUserModal;