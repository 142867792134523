import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { Buildings } from "react-bootstrap-icons";
import InfoIcon from "@mui/icons-material/Info";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SvgIcon from '../SVG/SvgIcons';
import settings from '../../config/Config';
import { get } from 'lodash';

export type FilterOption = 'All Locations' | 'Coimbatore' | 'Chennai' | 'Bangalore' | 'All Statuses' | string;

export interface FilterProps {
  options: any[];
  onSelectStatus: (status: any) => void;
  radiobutton: boolean;
  reset: boolean;
  defaultValue?: FilterOption;
}

const Filter: React.FC<FilterProps> = ({
  options,
  onSelectStatus,
  radiobutton,
  reset,
  defaultValue = 'All Statuses'
}) => {
  const [selectedValue, setSelectedValue] = useState<FilterOption>(defaultValue);
  const filterOptions = get(settings, 'constants.status', []);
  const workOptions = get(settings, 'constants.workOption', []);

  useEffect(() => {
    setSelectedValue(defaultValue);
  }, [reset, radiobutton, defaultValue]);

  const getStatusIcon = (status: string) => {
    switch (status.toLowerCase()) {
      case filterOptions[0]?.toLowerCase():
        return <CheckCircleOutlineIcon style={{ color: "blue", width: "20px", height: "20px" }} className="mb-1" />;
      case filterOptions[1]?.toLowerCase():
        return <HighlightOffIcon style={{ color: "red", width: "20px", height: "20px" }} className="mb-1" />;
      case filterOptions[2]?.toLowerCase():
        return <CancelRoundedIcon style={{ color: "black", width: "20px", height: "20px" }} className="mb-1" />;
      case filterOptions[3]?.toLowerCase():
        return <InfoIcon style={{ color: "#ffc108", width: "20px", height: "20px" }} className="mb-1" />;
      default:
        return null;
    }
  };

  const getWorkIcon = (option: string) => {
    switch (option) {
      case workOptions[0]:
        return <Buildings style={{ width: "20px", height: "20px" }} className="mb-1" />;
      case workOptions[1]:
        return <SvgIcon iconType="remoteWork" width={20} height={20} />;
      default:
        return null;
    }
  };

  const getLocationIcon = (location: string) => {
    if (location === 'All Locations') {
      return <Buildings style={{ width: '20px', height: '20px' }} className="mb-1" />;
    }
    return <SvgIcon iconType="location" width={20} height={20} />;
  };

  const getIcon = (value: string) => {
    if (options.includes('All Locations') || options.includes('Coimbatore')) {
      return getLocationIcon(value);
    }
    else if (workOptions.includes(value)) {
      return getWorkIcon(value);
    }
    return getStatusIcon(value);
  };

  const handleSelect = (value: FilterOption) => {
    setSelectedValue(value);
    onSelectStatus(value);
  };

  
  const dropdownOptions = options.filter(option => option !== 'All Locations');

  return (
    <Dropdown className="rounded-lg bg-white shadow-sm">
      <Dropdown.Toggle
        id="filter-dropdown"
        variant="light"
        className="d-flex justify-content-between align-items-center"
        style={{
          color: 'black',
          width: '240px',
          border: '1px solid #dee2e6',
          background: '#ffff',
        }}
      >
        <span className="d-flex align-items-center gap-1">
          <FilterAltOutlinedIcon style={{ color: 'green' }} />
          {getIcon(selectedValue)}
          <span>{selectedValue}</span>
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu style={{ minWidth: '200px' }}>
        {dropdownOptions.map((option, index) => (
          <Dropdown.Item
            key={index}
            onClick={() => handleSelect(option)}
            className="d-flex align-items-center"
          >
            <span style={{ marginRight: '8px' }}>{getIcon(option)}</span>
            {option}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default Filter;